import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/MoreDetails.scss";
import {
  getVideoUrl,
  getSongVimeoId,
  getTrailerVimeoId,
  durationUpdateApi,
} from "../../services/home";
import Vimeo from "@u-wave/react-vimeo";
import Spinner from "react-bootstrap/Spinner";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Rating from "../../common/rating/Rating";

export default function WatchMovie() {
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isMovieEnded, setIsMovieEnded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);
  const id = params.id;
  const type = queryParams.get("type");
  const songId = queryParams.get("songId");
  const trailerId = queryParams.get("trailerId");

  const vimeoRef = useRef(null);

  const requestFullscreen = () => {
    const player = vimeoRef.current?.player;
    if (player) {
      player.requestFullscreen().catch((error) => {
        console.error("Error attempting to enter full screen:", error);
        setTimeout(() => {
          requestFullscreen();
        }, 1000);
      });
    }
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === "d" || event.key === "D") {
  //     event.preventDefault(); // Prevent default behavior
  //     event.stopPropagation(); // Stop the event from propagating
  //     console.log("D key press disabled!");
  //   }
  // };

  // useEffect(() => {
  //   // Add the event listener for keydown
  //   window.addEventListener("keydown", handleKeyDown);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  // useEffect(() => {
  //   // Keydown event handler
  //   const handleKeyDown = (event) => {
  //     if (event.key === "d" || event.key === "D") {
  //       event.preventDefault(); // Prevent default behavior
  //       event.stopPropagation(); // Stop the event from propagating
  //       console.log("D key press disabled!");
  //     }
  //   };

  //   // Post message handler to listen for key events from iframe
  //   const handleIframeMessage = (event) => {
  //     console.log(
  //       "D key press handleIframeMessage!",
  //       event.origin,
  //       event.data,
  //       event.data.event
  //     );

  //     if (
  //       event.origin.includes("https://player.vimeo.com") && // Ensure it's from Vimeo
  //       event.data &&
  //       typeof event.data === "object" &&
  //       event.data.event === "keydown"
  //     ) {
  //       const key = event.data.data.key;
  //       if (key === "d" || key === "D") {
  //         event.preventDefault();
  //         event.stopPropagation();
  //         console.log("D key press inside iframe disabled!");
  //       }
  //     }
  //   };

  //   // Add event listener for iframe messages
  //   window.addEventListener("message", handleIframeMessage);

  //   // Add keydown event listener for non-iframe areas
  //   window.addEventListener("keydown", handleKeyDown);

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //     window.removeEventListener("message", handleIframeMessage);
  //   };
  // }, []);

  const handleMoviePlayClick = async () => {
    setLoading(true);
    if (type !== "song" && type !== "trailer" && !songId && !trailerId) {
      try {
        const response = await getVideoUrl(id);
        if (response && response.data) {
          setVideoUrl(response.data.data.vimeo_video_id);
          setDuration(response.data.data.watch_time);
          setPlayVideo(true);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSongPlayClick = async () => {
    setLoading(true);
    if (type === "song" && songId) {
      try {
        const response = await getSongVimeoId(Number(songId));
        if (response && response.data) {
          setVideoUrl(response.data.data.vimeo_video_id);
          setPlayVideo(true);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTrailerPlayClick = async () => {
    setLoading(true);
    if (type === "trailer" && trailerId) {
      try {
        const response = await getTrailerVimeoId(Number(trailerId));
        if (response && response.data) {
          setVideoUrl(response.data.data.vimeo_video_id);
          setPlayVideo(true);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (type === "song" && songId) {
      handleSongPlayClick();
    }
  }, [songId, type]);

  useEffect(() => {
    if (type === "trailer" && trailerId) {
      handleTrailerPlayClick();
    }
  }, [trailerId, type]);

  useEffect(() => {
    if (type !== "trailer" && !trailerId && type !== "song" && !songId) {
      handleMoviePlayClick();
    }
  }, [songId, trailerId, type]);

  const handleError = (error) => {
    toast.error("Video has been removed by Admin.");
    setTimeout(() => {
      setPlayVideo(false);
      setVideoUrl(null);
      navigate(`/moredetails/${id}`);
    }, 1500);
  };

  const updateDuration = async () => {
    if (
      type !== "trailer" &&
      !trailerId &&
      type !== "song" &&
      !songId &&
      (id !== "0" || id !== 0)
    ) {
      try {
        const payload = {
          movie_id: id,
          duration: duration,
        };
        await durationUpdateApi(payload);
      } catch (error) {
        console.log("Error in updating duration", error);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      updateDuration();
      const message = "Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        updateDuration();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [duration]);

  const handleEnd = async () => {
    updateDuration();
    setIsMovieEnded(true);
  };

  return (
    <>
      {isMovieEnded && <Rating selectedMovie={id} />}
      {!isMovieEnded && (
        <div className="main-back">
          <div className="detail-container">
            {loading ? (
              <div className="loader">
                <Spinner animation="border" variant="warning" />
              </div>
            ) : (
              <div className="image-section">
                {playVideo && videoUrl && (
                  <div className="video-player">
                    {/* <Vimeo
                      video={videoUrl}
                      autoplay
                      responsive
                      onError={handleError}
                      ref={vimeoRef}
                      onTimeUpdate={(time) => setDuration(time.seconds)}
                      onPause={updateDuration}
                      start={duration}
                      onEnd={handleEnd}
                      onReady={handleReady}
                    /> */}
                    <Vimeo
                      video={videoUrl}
                      autoplay
                      responsive
                      onError={handleError}
                      ref={vimeoRef}
                      keyboard={false}
                      onTimeUpdate={(time) => {
                        const currentTime = time.seconds;

                        // Check if the user seeks forward
                        if (currentTime > duration + 1) {
                          vimeoRef.current?.player.setCurrentTime(duration); // Reset to last valid duration
                        }
                        // Check if the user seeks backward
                        else if (currentTime < duration - 1) {
                          vimeoRef.current?.player.setCurrentTime(duration); // Reset to last valid duration
                        }
                        // Update duration if no seek is detected
                        else {
                          setDuration(currentTime);
                        }
                      }}
                      onPause={updateDuration}
                      start={duration}
                      onEnd={handleEnd}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
